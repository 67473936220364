import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { apiUsers } from '../services/api/utilities';
import CryptoJS from 'crypto-js';

// Persist Mechanism #1
// - Arrive
// - 1) Session ?
// -- No
// --- Auth
// - 2) DB Persist ? Is there a db persist entry?
// -- Yes
// --- Fetch DB entry
// --- Overwrite localStorage
// - 3) Persist from localStorage
// - 4) Init app

// Persist Mechanism #2
// - Update DB Persist
// -- On logout
// -- On close
// -- Every x minutes


function usePersistFromDb() {
  const dispatch = useDispatch();
  const token = useSelector(state => state.token === undefined ? "" : state.token);
  const [lastModified, setLastModified] = useState();
  const [persistComplete,setPersistComplete] = useState(false);
  // Which reducers to persist from DB
  const persistReducersDB = [
    "user",
    "dashboard",
    "interface",
    "products",
    "clients",
    "bills"
  ];
  const blackList = {
    interface : ["geography"]
  }

  const clearLocalStorage = () => {
    localStorage.setItem('persist:root','');
  }


  const getPersist = () => {

    return apiUsers.getPersist()
      .then(data => {
        setLastModified(data.modified);
        if(data.hasOwnProperty('error')) {
          updatePersist().then(newData => (CryptoJS.AES.decrypt(newData.root,process.env.REACT_APP_SALT).toString(CryptoJS.enc.Utf8)))
        }
        return (CryptoJS.AES.decrypt(data.root,process.env.REACT_APP_SALT).toString(CryptoJS.enc.Utf8));
      });
  }


  const parseStorage = (storeString) => {
    // BLACKLIST INCLUDED
    let storeAll = JSON.parse(storeString);
    let payload = {};
    Object.keys(storeAll).filter((reducer) => persistReducersDB.includes(reducer)).forEach((section) => {
      payload[section] = JSON.parse(storeAll[section]);
      if(Object.keys(blackList).includes(section)) {
        blackList[section].forEach((propOff, i) => {
          delete payload[section][propOff];
        });
      }
    });
    return payload;
  }

  const stringifyStorage = (storeObj) => {
    Object.keys(storeObj).forEach((section) => {
      storeObj[section] = JSON.stringify(storeObj[section]);
    });
    return JSON.stringify(storeObj);
  }

  const updatePersist = () => {
    let storeObj = parseStorage(localStorage.getItem('persist:root')); // BLACKLIST INCLUDED
    let storeStr = stringifyStorage(storeObj);

    return apiUsers.makePersist({
      persist : CryptoJS.AES.encrypt(
        storeStr,
        process.env.REACT_APP_SALT
      ).toString()
    })
  }

  const getLastStoreUser = (id) => {
    let storeObj = parseStorage(localStorage.getItem('persist:root'));
    return storeObj.user.id;
  }

  const updateStateFromDB = () => {
    return getPersist()
      .then(data => {

        if (Object.keys(data).length === 0) return {};

        let dbState = parseStorage(data);
        dispatch({ type : 'UPDATE_FROM_DB', payload : dbState });
        setPersistComplete(true);
        return parseStorage(data);
      });
      // }).then((data) => setPersistComplete(true));
  }



  return { persistComplete, lastModified, getLastStoreUser, getPersist, updatePersist, updateStateFromDB };
}

export default usePersistFromDb
