import { configureStore } from '@reduxjs/toolkit';
import { createMigrate, persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


import rootReducer from '../reducers'


const migrations = {
  0 : (state)  => {
    return {
      ...state,
      id : '123',
      hi : '456'
    }
  }
};

const persistConfig = {
  version: 0,
  key: 'root',
  storage,
  migrate: createMigrate(migrations, { debug : true }),
  // migrate : (state) => {
  //   console.log("Migration running!");
  //   return Promise.resolve(state)
  // }
}


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore(
  {reducer : persistedReducer},
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const persistor = persistStore(store);

export default () => {
  return { store, persistor }
}
